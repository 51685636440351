import React from "react";
import { Switch, Route } from "react-router-dom";
import Tarot from './pages/tarot/Tarot';
import TarotDetail from './pages/tarot-detail/TarotDetail';
import Help from "./pages/help/Help";
import Terms from "./pages/terms/Terms";
import TarotRandom from "./pages/tarot-random/TarotRandom";

export default function App() {
  return (
    <div id={'app'}>
      <Switch>
        <Route path="/detaljna-analiza/:smsCode">
          <TarotDetail />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/:randomString/detaljna-analiza/:smsCode">
          <TarotDetail />
        </Route>
        <Route path="/:randomString/terms">
          <Terms />
        </Route>
        <Route path="/:randomString">
          <Tarot />
        </Route>
        <Route path="/">
          <Tarot />
        </Route>
      </Switch>
    </div>
  );
}
