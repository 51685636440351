import React from "react";


class Terms extends React.Component {
  render() {
    return(
      <div class="wrapper">
        <div class="header">
            <nav class="navbar navbar-default">
                <div class="container">
                    <div class="navbar-header">
                        {/*<a class="navbar-brand" href={window.location.href.replace(/\/terms/g, '')}><img src={`${process.env.PUBLIC_URL}/img/logo.png`} width="200px"/></a>*/}
                    </div>
                </div>
            </nav>
        </div>
        <div class="main-content">
          <div class="container uputstvo" style={{paddingTop: 0}}>
            <div class="col-sm-12"> 
                <div class="row" style={{paddingBottom: "5px"}}>
                  <h2><strong>Uslovi korišćenja usluge „moj-tarot“</strong></h2>
                </div>
                <div class="row" style={{marginTop: "32px", textAlign: "start"}}>              
                    <p>1. Usluga „moj-tarot“ je dostupna korisnicima mobilnih mreža operatera: MTS, Telenor, Vip, Globaltel</p>
                    <p>2. Usluga „moj-tarot“ služi jedino i isključivo za zabavu.</p>
                    <p>3. Da biste dobili pristup uslugama koje se nude na sajtu treba da pošaljete SMS poruku na određen broj, naveden na veb sajtu.</p>
                    <p>4. Korisnik je isključivo odgovoran za slanje SMS poruke koja sadrži pravilan sadržaj (tekst SMS-a) i pravilan broj. Ako pošaljete SMS poruku sa netačnim sadržajem ili na pogrešan broj, dobićete odgovor o grešci.</p>
                    <p>5. Pružalac usluge je Comparo Media d.o.o. Tehnički pružalac usluge NTH Media d.o.o. Beograd.</p>
                    <p>6. Ovo nije pretplatna usluga. Cena jedne SMS poruke na broj 8807 je 450 dinara sa PDV (+ cena standardne MO SMS poruke: MTS 3,60 Din, Telenor 3,60 Din, VIP 3,48 Din, Globaltel-besplatno)</p>
                    <p>7. Pružalac usluge zadržava svoje pravo da promeni pravila korišćenja usluge u svako doba. Pružalac usluge će objaviti nove uslove na Internet stranici.</p>
                    <p>8. Korisnik je saglasan da koristi uslugu u skladu sa tim pravilima i isključivo na način koji je u skladu sa primenjivim pravom, pravilima o socijalnom ponašanju i moralu, uz poštovanje prava intelektualne svojine.</p>
                    <p>9. Slanjem SMS poruke korisnik izražava svoju saglasnost da dobija obaveštenja o akcijama i drugim uslugama koje se nude preko pružaoca.</p>
                    <p>10. Mobilni operateri su odgovorni samo za ostvarivanje telekomunikacione usluge i ne snose odgovornost za ponuđene usluge, posebno sadržaj i informacije, koje oni nude.</p>
                    <p>11. Pružalac ne snosi odgovornost za neispravnost usluge kao rezultat nepravilnog funkcionisanja mreže odgovarajućeg mobilnog operatera</p>
                    <p>12. Služba za korisnike radnim danom od 09-17h, +381113216866, mail: comparo.rs@silverlines.info.</p>
                    <p>13. Sadržaj veb sajta je predmet autorskog prava i zaštićen je Zakonom o autorskom pravu i njemu srodnim pravima.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Terms;