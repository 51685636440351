import React from "react";


class Help extends React.Component {
  render() {
    return(
      <div class="wrapper">
        <div class="header">
            <nav class="navbar navbar-default">
                <div class="container">
                    <div class="navbar-header">
                        {/*<a class="navbar-brand" href="/"><img src={`${process.env.PUBLIC_URL}/img/logo.png`} width="200px"/></a>*/}
                    </div>
                </div>
            </nav>
        </div>
        <div class="main-content">
          <div class="container uputstvo">
            <div class="col-sm-12"> 
              <div class="row">
                <h2><strong>Pomoć</strong></h2>
                <p>Ovaj sadržaj je isključivo namijenjen osobama starijim od 18 godina. Ukoliko niste napunili 18 godina, molimo Vas napustite stranicu astro24.</p>
                <p>Ukoliko imate više pitanja ili pritužbu, molimo Vas kontaktirajte službu za korisnike koja Vam je dostupna na E Mailu comparo.hr@ccsupport.biz, ili na broju telefona 042500871 svakim radnim danom od 09:00-17:00 sati.</p>
                <p>Za deaktivaciju usluge u bilo kojem trenutku, pošaljite stop astro na 862862. Organizator usluge: Comparo Media d.o.o., Andrije Štangera 61, 51410 Opatija, OIB: 80948414608. Tehnička podrška (davatelj usluge): NTH Mobile d.o.o., Međimurska 28, 42000 Varaždin: OIB: 12385860076, Tel: 042500870</p>
                <p>Krajnji korisnik prihvaća Uvjete korištenja te svjesno i samovoljno koristi navedene usluge.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Help;